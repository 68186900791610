import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { combineLatest, lastValueFrom, Observable, timer } from 'rxjs';
import { debounce, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AssociatedBrand } from 'src/app/models/associated-brand';
import { IAuthenticationAction } from 'src/app/models/authentication-action';
import { AuthenticationResult } from 'src/app/models/authentication-result';
import { LoginInputModel } from 'src/app/models/login-input-model';
import { IOrganisationMasterData } from 'src/app/models/organisation-master-data';
import { LogoService } from 'src/app/services/logo.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  DATE_FORMATS,
  DistanceUnit,
  EMAIL_PATTERN,
  getPasswordToolTip,
  getTimeZones,
  isValidPasswordInput,
  LANGUAGES,
  NumberFormat,
  TimeZoneUnit,
  USER_ACCOUNT_NAME_PATTERN,
  VAT_PATTERN,
} from 'src/app/shared/constants';
import { InstantErrorStateMatcher } from 'src/app/shared/instant-error-state-matcher';
import { IRegistrationModel } from '../../models/registration-model';
import { AuthService } from '../../services/auth.service';
import { InvitationService } from '../../services/invitation.service';
import { PrivacyPolicyDialogComponent } from '../dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsOfServiceDialogComponent } from '../dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { ILoginConfig } from '../login-dialog/login-dialog.component';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  providers: [{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }],
})
export class InviteComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  readonly #logoService = inject(LogoService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #invitationService = inject(InvitationService);
  readonly #authService = inject(AuthService);
  readonly #dialog = inject(MatDialog);
  readonly #translate = inject(TranslateService);
  readonly #notificationService = inject(NotificationService);

  loading = true;

  invite: IInviteParams;

  invitingPartner: IOrganisationMasterData;
  error: string;

  waitingForRegisterResponse = false;
  registrationCompleted = false;

  tab: 'register' | 'login' = 'login';

  languages: string[] = LANGUAGES;
  dateFormats: string[] = DATE_FORMATS;
  numberFormats: { key: string; value: number }[] = [];
  timeZones = getTimeZones();
  distanceSystems: { key: string; value: number }[] = [];

  passwordRestrictionsTooltip = '';

  loginSettings: ILoginConfig = {
    login: this.performLogin.bind(this),
    register: this.setTab.bind(this, 'register'),
    mode: 'LOGIN_AND_ACCEPT',
  };

  errorStateMatcher = new InstantErrorStateMatcher();

  companyForm = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    vatNr: new FormControl('', [Validators.required, Validators.pattern(VAT_PATTERN)], [this.vatNumberValidator()]),
    country: new FormControl(null, [Validators.required]),
    street: new FormControl('', [Validators.required]),
    houseNr: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    phonePrefix: new FormControl('', [Validators.required, Validators.pattern(/^\d{1,3}$/)]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
  });

  userForm = new FormGroup({
    mail: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    login: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(100),
      Validators.pattern(USER_ACCOUNT_NAME_PATTERN),
    ]),
    organisation: new FormControl(
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(36), Validators.pattern(USER_ACCOUNT_NAME_PATTERN)],
      [
        (control) =>
          timer(500).pipe(
            switchMap(() => this.#authService.organisationNameAllowed(control.value)),
            map((allowed) => (allowed ? null : { alreadyExists: true })),
          ),
      ],
    ),
    password: new FormControl('', [
      Validators.required,
      (control) => (isValidPasswordInput(control.parent?.value?.login, control.value) ? null : { syntax: true }),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      (control) => (isValidPasswordInput(control.parent?.value?.login, control.value) ? null : { syntax: true }),
      (control) => (control.parent?.value?.password !== control.value ? { notSame: true } : null),
    ]),
  });

  userSettingsForm = new FormGroup({
    language: new FormControl('', [Validators.required]),
    dateFormat: new FormControl(null, [Validators.required]),
    numberFormat: new FormControl(null, [Validators.required]),
    timeZone: new FormControl(null, [Validators.required]),
    distanceSystem: new FormControl(null, [Validators.required]),
  });

  confirmationForm = new FormGroup({
    termsOfService: new FormControl(false, [Validators.requiredTrue]),
    privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
  });
  currentStep = 0;

  filteredOptions: Observable<{ countryShort: string; countryFull: string }[]>;
  languageCodes: [{ countryShort: string; countryFull: string[] }];
  nocountrys = false;

  openHelpDialog(): void {
    this.#dialog.open(HelpDialogComponent, {
      width: '600px',
      restoreFocus: false,
    });
  }

  stepChange(event: any): void {
    this.currentStep = event.selectedIndex;

    if (this.currentStep === 2) {
      this.userSettingsForm.markAsTouched();
    }
  }

  openTermsOfServiceDialog(): void {
    this.#dialog.open(TermsOfServiceDialogComponent, {
      restoreFocus: false,
    });
  }

  openPrivacyPolicyDialog(): void {
    this.#dialog.open(PrivacyPolicyDialogComponent, {
      restoreFocus: false,
    });
  }

  async performLogin(model: LoginInputModel): Promise<AuthenticationResult | null | undefined> {
    let result: IAuthenticationAction;

    if (this.loginSettings.mode === 'LOGIN_OR_REGISTER_AND_ACCEPT') {
      result = await lastValueFrom(this.#authService.loginConnectInvite(model, this.invite.relationUuid, this.invite.token));
    } else if (this.loginSettings.mode === 'LOGIN_AND_ACCEPT') {
      result = await lastValueFrom(this.#authService.loginAcceptInvite(model, this.invite.relationUuid, this.invite.token));
    } else {
      result = await lastValueFrom(this.#authService.login(model));
    }

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      return null;
    }

    return result?.result;
  }

  async performRegistration(): Promise<void> {
    this.waitingForRegisterResponse = true;

    const registration = {
      user: {
        ...this.userForm.value,
        ...this.userSettingsForm.value,
      },
      organisation: {
        ...this.companyForm.value,
      },
    } as IRegistrationModel;

    registration.organisation.country = this.companyForm.controls['country'].value.countryShort;
    registration.organisation.email = this.companyForm.controls['email'].value;
    registration.organisation.tel = '+' + this.companyForm.controls['phonePrefix'].value + this.companyForm.controls['phone'].value;

    const result = await lastValueFrom(this.#authService.registerAcceptInvite(registration, this.invite.relationUuid, this.invite.token));

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
    } else if (result.result === AuthenticationResult.NotAnymoreAllowed) {
      this.#notificationService.openSnackBar('LOGIN.INVITATION_EXPIRED', false);
    } else {
      this.#notificationService.openSnackBar('LOGIN.INVITATION_INVALID', false);
    }

    this.waitingForRegisterResponse = false;
  }

  setTab(tab: 'login' | 'register'): void {
    if (!this.registrationCompleted) {
      this.tab = tab;
    }
  }

  private vatNumberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      timer(500).pipe(
        switchMap(() => this.#authService.vatPreflightCheck(control.value)),
        map((ok) =>
          ok
            ? null
            : {
                vatExists: true,
              },
        ),
      );
  }

  private filterCountryInput(value: string): { countryShort: string; countryFull: string }[] {
    const filterValue = value.toLowerCase();

    const filteredOptions = this.languageCodes.reduce((previous, current) => {
      const index = current.countryFull.findIndex((full) => full.toLowerCase().includes(filterValue));
      if (index > -1) {
        previous.push({ countryShort: current.countryShort, countryFull: current.countryFull[index] });
      }
      return previous;
    }, []);

    this.nocountrys = filteredOptions.length === 0;
    this.companyForm.controls['country'].markAsDirty();
    return filteredOptions;
  }

  showcountryFull(country): string {
    return country.countryFull;
  }

  checkValidCountry(): void {
    if (typeof this.companyForm.controls['country'].value === 'string') {
      this.companyForm.controls['country'].setErrors({ countryError: true });
      return null;
    }
    const filterValue = this.companyForm.controls['country'].value.countryFull.toLowerCase();

    if (!this.languageCodes.find((country) => country.countryFull.find((full) => full.toLowerCase() === filterValue))) {
      this.companyForm.controls['country'].setErrors({ countryError: true });
    } else {
      this.companyForm.controls['country'].setErrors(null);
    }
  }

  async ngOnInit(): Promise<void> {
    let isInitial = true;
    this.filteredOptions = combineLatest([
      this.#invitationService.getCountries().pipe(tap((c) => (this.languageCodes = c))),
      this.companyForm.controls['country'].valueChanges.pipe(startWith({ countryShort: 'DE', countryFull: 'Germany' })),
    ]).pipe(
      debounce(() => (isInitial ? timer(0) : timer(500))),
      tap(() => (isInitial = false)),
      map(([, value]) => {
        if (typeof value === 'string') {
          return this.filterCountryInput(value ?? '');
        } else {
          return this.filterCountryInput(value.countryFull ?? '');
        }
      }),
    );

    this.companyForm.controls['country'].setValue({ countryShort: 'DE', countryFull: 'Germany' });

    this.distanceSystems = Object.keys(DistanceUnit)
      .filter((item) => {
        return !isNaN(Number(item));
      })
      .map((unit) => {
        return { key: DistanceUnit[unit], value: +unit };
      });
    this.numberFormats = Object.keys(NumberFormat)
      .filter((item) => {
        return !isNaN(Number(item));
      })
      .map((unit) => {
        return { key: NumberFormat[unit], value: +unit };
      });

    this.passwordRestrictionsTooltip = await getPasswordToolTip(this.#translate);

    this.userSettingsForm.setValue({
      language: this.languages[0],
      dateFormat: this.dateFormats[0],
      numberFormat: NumberFormat.DOT,
      timeZone: TimeZoneUnit.UTC,
      distanceSystem: DistanceUnit.KILOMETER,
    });

    this.userForm.controls['login'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.userForm.controls['password'].updateValueAndValidity();
    });

    this.#activatedRoute.params
      .pipe(
        map((p) => p as IInviteParams),
        tap((p) => {
          this.loading = true;
          this.invite = p;
        }),
        switchMap((p) => this.#invitationService.getOrganisationMasterData(p.relationUuid, p.token)),
        untilComponentDestroyed(this),
      )
      .subscribe((httpResponse) => {
        if (!httpResponse.ok) {
          this.error = httpResponse.status === 410 ? 'LOGIN.INVITATION_EXPIRED' : 'LOGIN.INVITATION_INVALID';
          this.loginSettings.mode = 'LOGIN';
        } else {
          this.invitingPartner = httpResponse.body;

          if (this.invitingPartner.associatedBrand !== undefined) {
            this.#logoService.setLogoUrl(AssociatedBrand[this.invitingPartner.associatedBrand]);
          }

          this.loginSettings.mode = this.invite.type === 'new' ? 'LOGIN_OR_REGISTER_AND_ACCEPT' : 'LOGIN_AND_ACCEPT';

          if (this.invite.type === 'new' && this.invite.email) {
            this.userForm.setValue({ ...this.userForm.value, mail: this.invite.email } as any);
          }
        }

        this.loading = false;
      });
  }
}

interface IInviteParams {
  type: 'new' | 'existing';
  relationUuid: string;
  token: string;
  email: string;
}
