import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from './directives/button-loading.directive';
import { TrimDirective } from './directives/trim.directive';
import { MaterialModule } from './material.module';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, TranslateModule, MatDialogModule],
  declarations: [ButtonLoadingDirective, TrimDirective],
  exports: [FormsModule, ReactiveFormsModule, MaterialModule, TranslateModule, ButtonLoadingDirective, TrimDirective, MatDialogModule],
  providers: [provideHttpClient(), { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
})
export class SharedModule {}
