import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';

export class TranslateHttpPostLoader implements TranslateLoader {
  readonly whitelist = [
    'BACKEND_ERROR',
    'ERROR',
    'FRAME',
    'GENERAL',
    'IMPRESSUM',
    'LANG',
    'LOGIN',
    'PRIVACY_POLICY',
    'TERMS_OF_SERVICE',
    'TIME_ZONE_INTL',
    'USER',
  ];

  constructor(
    private http: HttpClient,
    private prefix: string = '',
    private suffix: string = '',
  ) {}

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`, { params: { whitelist: this.whitelist.join(',') } });
  }
}
