import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AppComponent } from './app-component/app.component';
import { AppRoutingModule } from './app-routing.module';
import { InviteComponent } from './components/invite/invite.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpPostLoader } from './shared/translate-http-post-loader';
import { ImprintDialogComponent } from './components/dialogs/imprint-dialog/imprint-dialog.component';
import { TermsOfServiceDialogComponent } from './components/dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';

registerLocaleData(localeDe, 'de-De', localeDeExtra);

@NgModule({ declarations: [
        AppComponent,
        InviteComponent,
        LoginComponent,
        RedirectComponent,
        LogoutComponent,
        LoginDialogComponent,
        InvoiceComponent,
        ImprintDialogComponent,
        TermsOfServiceDialogComponent,
        PrivacyPolicyDialogComponent,
        SnackbarComponent,
        HelpDialogComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        SharedModule], providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: LOCALE_ID, useValue: 'de-De' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpPostLoader(http, '/api/i18n/', '');
}
