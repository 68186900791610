<div class="header blue-border panel-border-bottom">
  <div class="title-wrapper flex-row gap-05">
    <h3 class="title">
      {{ 'DPA.DPA_DIALOG_HEADLINE' | translate }}
    </h3>

    <button
      data-testid="dpaDialogDownloadDpaTextAsPdfIconButton"
      mat-icon-button
      (click)="downloadDpaTextAsPdf()"
      [appButtonLoading]="pdfDownloading"
      [disabled]="pdfDownloading"
    >
      <mat-icon color="primary">download</mat-icon>
    </button>

    <mat-form-field class="no-hint-padding">
      <mat-label>
        {{ 'LANG.LANGUAGES' | translate }}
      </mat-label>

      <mat-select [(ngModel)]="selectedLanguage" data-testid="dpaDialogLanguageSelect" name="language">
        @for (lang of dpaConfirmationRequested?.languages; track lang) {
          <mat-option [value]="lang" attr.data-testid="dpaDialogLanguageOption-{{ lang }}">
            {{ 'LANG.LANGUAGE-' + (lang | uppercase) | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="flex-column gap-05 overflow-hidden-auto padding-1 flex-1-0-0">
  @if (data.mode === 'accept') {
    <form [formGroup]="dpaForm" class="flex-row gap-05 f-ai-s">
      <mat-form-field>
        <mat-label>
          {{ 'DPA.CLIENT' | translate }}
        </mat-label>

        <input matInput formControlName="client" data-testid="dpaDialogClientInput" />

        <ng-container matSuffix>
          @if (
            (dpaForm.controls['client'].touched || dpaForm.controls['client'].dirty) &&
            !dpaForm.controls['client'].valid &&
            dpaForm.controls['client'].errors?.required
          ) {
            <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
          }
        </ng-container>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'DPA.SIGNED_BY_FIRST_NAME' | translate }}</mat-label>

        <input matInput formControlName="firstName" type="text" data-testid="dpaDialogSignedByFirstNameInput" />
        <ng-container matSuffix>
          @if (
            (dpaForm.controls['firstName'].touched || dpaForm.controls['firstName'].dirty) &&
            !dpaForm.controls['firstName'].valid &&
            dpaForm.controls['firstName'].errors?.required
          ) {
            <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
          }
        </ng-container>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'DPA.SIGNED_BY_LAST_NAME' | translate }}</mat-label>

        <input matInput formControlName="lastName" type="text" data-testid="dpaDialogSignedByLastNameInput" />

        <ng-container matSuffix>
          @if (
            (dpaForm.controls['lastName'].touched || dpaForm.controls['lastName'].dirty) &&
            !dpaForm.controls['lastName'].valid &&
            dpaForm.controls['lastName'].errors?.required
          ) {
            <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
          }
        </ng-container>
      </mat-form-field>
    </form>
  }

  <span>{{ 'DPA.EXPLANATION' | translate }}</span>

  <div class="dpa-text-box panel-border">
    @if (languageLoadingState === 'success') {
      <span [innerHtml]="previewTextDpaConfirmationRequested | safeHtml"> </span>
    }
    @if (languageLoadingState === 'loading') {
      <span class="spinner"></span>
    }
    @if (languageLoadingState === 'error') {
      <span class="info-message">{{ 'DPA.ERROR_LOADING_PREVIEW' | translate }}</span>
    }
    @if (languageLoadingState === 'noSelection') {
      <span class="info-message">{{ 'DPA.NO_LANGUAGE_SELECTED' | translate }}</span>
    }
  </div>

  @if (data.mode === 'accept') {
    <mat-checkbox data-testid="dpaDialogOptInCheckbox" [(ngModel)]="optInChecked">{{ 'DPA.OPT_IN_CHECKBOX' | translate }}</mat-checkbox>
  }
</div>

<div class="flex-row gap-05 padding-1 panel-border-top">
  <span class="flex-spacer"></span>

  @if (data.mode === 'accept') {
    <button
      mat-flat-button
      color="primary"
      [disabled]="
        !optInChecked || !dpaForm.controls['firstName'].valid || !dpaForm.controls['lastName'].valid || !dpaForm.controls['client'].valid
      "
      (click)="closeViaAcceptedButton()"
      data-testid="dpaDialogAcceptButton"
    >
      {{ 'DPA.ACCEPT' | translate }}
    </button>
  } @else {
    <button mat-flat-button data-testid="dpaDialogCloseButton" color="primary" mat-dialog-close>
      {{ 'GENERAL.CLOSE' | translate }}
    </button>
  }
</div>
